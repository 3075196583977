.blog__container {
    padding: 0px 110px;
    display: flex;
}

.blog__container h1 {
    letter-spacing: -1px;
}

.blog__container h2 {
    letter-spacing: -1px;
}

.blog__container h3 {
    letter-spacing: -0.5px;
}


.blog__leftcontainer {
    width: 50vw;
}

.blog__title {
    margin-bottom: -20px;
}

.blog__contents {

    text-wrap: wrap;
}

.blog__text {
    margin-bottom: 30px;
}

.blog__rightcontainer {
    width: 50vw;
    height: 65vh;
    display: flex;

    align-items: center;

}

.blog__imgcontainer {
    position: fixed;
}

.blog__highlight {
    background-color: #fffae5;
    transition: background-color 0.3s ease-in-out;
}