.contact__formcontainer {
    display: flex;
    height: 50vh;
    flex-direction: column;
    width: 70vw;

}

.support__contentcontainer {
    color: white;
    min-height: fit-content;
    height: 700px;

}

.support__container {
    width: 100vw;
    display: flex;
    justify-content: center;
    font-size: 18px;
}

.support__contentcontainer input {
    border: none;
    outline: none;
    min-height: 50px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.898);
    font-family: 'Avenir Medium';
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
}

.support__contentcontainer textarea {
    outline: none;
    border: none;
    background-color: #f0f0f0;
    font-weight: 400;
    font-size: 18px;
    border-radius: 10px;
    font-family: 'Avenir Medium';
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    margin-bottom: -2px;
    text-decoration-color: white;
    text-decoration-thickness: 2px;
    resize: none; 
    min-height: 200px;
}

.support__container button {
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.898);
    min-height: 80px;
    margin-top: 25px;
    border-radius: 10px;
    font-size: 18px;
    font-family: "Avenir Medium";
    cursor: pointer;
}

.support__contentcontainer label {
    margin-bottom: 5px;
    margin-top: 5px;
}

.support__info{

    color: rgba(255, 255, 255, 0.516);
    margin-left: 15vw;
}

.support__title{
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -1.7px;
}

.support__thanks{
    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: 10vw;
    color: white;
    font-size: 3vw;
    letter-spacing: -0.15vw;
}

.support__thanks__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.support__thanks__container button{
    padding-left: 30px;
    padding-right: 30px;
}
