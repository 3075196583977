.cookies__container {
    width: 747px;
    height: 520px;
    background-color: white;
    border-radius: 38px;
    display: flex;
    flex-direction: row;
    color: black;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.22);
}

.cookies__left {
    width: 271px;
    height: 100%;
    background-color: #f0f0f0;
    border-radius: 38px 0px 0px 38px;
}

.cookies__right {
    width: 476px;
    padding: 60px;
}

.cookies__info {
    color: #000;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -2.2px;
}

.cookies__button {
    border-radius: 20px;
    background-color: #2C81E4;
    width: 375px;
    height: 155px;
    color: white;
    font-size: 33px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
    cursor: pointer;
}

.cookies__buttontext {
    transform: translate(40px, 30px);

}

.cookies__reject {
    color: #ADADAD;
    letter-spacing: -1px;
    text-decoration: underline;
    cursor: pointer;
}