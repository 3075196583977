.thanks__title{
    color: white;
    font-size: 4vw;
    width: 20vw;
}

.thanks__show{
    color: white;
    font-size: 4vw;
}

.thanks__downloadbox{
    width: 14vw;
    height: 14vw;
    margin: 10px;
    border-radius: 10px;
    border: solid white 3px;
    font-size: 2vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.thanks__downloads{
  margin-top: 30px;
    display: flex;
    flex-direction: row;
    margin-bottom: 350px;
}

.thanks__downloads *{
    cursor: pointer;
}

.thanks__ontop {
    position: relative;
    margin-left: 6.4vw;
    margin-top: 20px;
}

.thanks__downloadunderlined{
    text-decoration: underline;
}

.loadingOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: white;
  }
  
  .spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #ababab;
    border-radius: 50%;
    width: 15vw;
    height: 15vw;
    animation: rotate 2s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  