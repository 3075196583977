.account__bg {
    position: absolute;
    width: 101vw;
    overflow: hidden;
    margin-left: -1vw;
    background-image: linear-gradient(to bottom, #9A7BC3, #C4829E, #C07FCC);
    height: 200vh;
    overflow: hidden;
    background-size: 400% 400%;
    background-color: red;;
}

.account__buttonscontainer {
    display: flex;
    justify-content: space-between;
    width: 80vw;
    margin-bottom: 15px;
}

.account__buttonscontainer button {
    margin: 0 10px;
    background: none;
    border: none;
    color: white;
    font-family: "Avenir Medium";
    cursor: pointer;
    font-size: 1.3vw;
    letter-spacing: -0.03vw;
}

.account__buttonscontainer button.selected {
    text-decoration: underline;
}

.dropdown{
    background: none;
    width: 87.2vw;
    border: white 2px;
    color: white;
    font-family: "Avenir Medium";
    cursor: pointer;
    background-color: rgba(198, 198, 198, 0.173);
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -0.0vw;
    height: 50px;
    padding-left: 10px;
}

.onepurchase__container{
    width: 87.2vw;
    background-color: rgb(255, 255, 255);
    height: 200px;
    margin-top: 12px;
    border-radius: 5px;
    filter: drop-shadow(4px 4px 10px #0000002e);
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.onepurchase__title{
    font-size: 2.5vw;
    font-family: "Avenir Medium";


    letter-spacing: -0.13vw;
}

.onepurchase__top{
    display: flex;
    justify-content: space-between;
    margin: 5px;
    align-items: center;
    padding-top: 10px;
}

.onepurchase__button{
    background-color: black;
    color: white;
    border-radius: 20px;
    padding: 7px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    letter-spacing: -0.64px;
}

.account__ontop{
    position: relative;
    margin-left: 6.4vw;
}

.account__title{
    font-size: 5vw;
    color: white;
    letter-spacing: -0.09vw;
    margin-top: 20px;
    margin-bottom: 20px;
}

.onepurchase__product{
    margin-left: 10px;
    font-size: 2vw;
}

.onepurchase__line{
    background-color: rgb(242, 242, 242);
    width: 100%;
    height: 2px;
    border-radius: 10px;
    margin-bottom: 7px;
    margin-top: 5px;
}