.about__textcontent {
    display: flex;
    padding-left: 6.4vw;
    padding-right: 6.4vw;
    width: 100%;
}


.about__left {
    font-size: 8vw;
    flex: 1;
    color: #273A55;
    letter-spacing: -0.4vw;
}

.about__right {
    flex: 1;
    display: flex;
    color: #273A55;
    margin-right: 10vw;
}


.about__infoleft,
.about__inforight {

    width: 18.5vw;
}

.about__inforight {

    transform: translate(7.3vw);
}



.about__gap {
    height: 55vh;
}

@media (max-width: 650px) {
    .about__textcontent {
        flex-direction: column;
    }

    .about__right {
        flex-direction: column;
    }

    .about__infoleft,
    .about__inforight {

        width: 87.2vw;
    }

    .about__inforight {
        transform: translate(0);
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .bottom-left-about,
    .bottom-right-about {
        transform: translate(0px, 54vw);
    }

}

.about-screen {
    display: flex;
    background-color: #F4F4F4;
    min-height: 100vh;
    flex-direction: column;
    overflow-x: hidden;
    /*background: linear-gradient(#edf2e2, #DAF8FF);*/
}

.about__info2 {
    margin-top: 2vw;
}

.bottom-left-about,
.bottom-right-about {
    position: absolute;
    bottom: 30px;
    /*color: #038961;*/
    color: #273A55;

}

.bottom-left-about {
    left: 6.4vw;
}

.bottom-right-about {
    right: 6.4vw;
    text-decoration: underline;
    cursor: pointer;

    flex-direction: column;
}

.about__img {

    position: absolute;
    width: 93.2vw;
    margin-left: 3.2vw;
    margin-right: 3.2vw;
    height: 63vh;

}

.about__imgsize {
    object-fit: cover;
    width: 100%;
    overflow: hidden;
    border-radius: 0px 0px 5vw 5vw;
    height: 63vh;

}

.about-blurry {
    width: 93.2vw;
    height: 120px;
    background-color: rgba(96, 96, 96, 0.2);
    position: absolute;
    backdrop-filter: blur(20px);
    margin-left: 3.2vw;
}

.about__gradient {
    width: 100%;
    height: 110px;
    position: absolute;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
