.lic__prod {
    width: 289px;
    height: 184px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    cursor: pointer;
    /*border: solid;
    border: 20px solid white;*/
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.lic__prodinfo{
    display: flex;
    background: -webkit-linear-gradient(to right, #3FA556, #4C6C57);
    background: linear-gradient(to right, #3FA556, #4C6C57);
    animation: gradient 15s ease infinite;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 257px;
    height: 154px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.20);
    font-family: 'Lexend';
    font-size: 48px;
    letter-spacing: -3.8px;
}

.lic__prodavailable{
    font-size: 28px;
    letter-spacing: -1.8px;
    transform: translate(0px, -30px);
}

.lic__prodtext{
    transform: translate(0px, 15px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.spinner {
    margin-left: 418px;
    margin-top: 137px;
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    border-top: 16px solid #ffffff79;
    margin-bottom: 700px;
}

.spinner-dot {
    display: inline-block;
    position: absolute;
    left: 80px;
    top: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ffffff;
    animation: spinner-animation 2s infinite ease-in-out;
}

.spinner-dot:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.6s;
}

.spinner-dot:nth-child(2) {
    transform: rotate(120deg);
    animation-delay: -0.4s;
}

.spinner-dot:nth-child(3) {
    transform: rotate(240deg);
    animation-delay: -0.2s;
}

@keyframes spinner-animation {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(0);
    }
}

.account__bg {
    position: fixed;
    /* Set position to fixed */
    top: 0;
    /* Align to the top of the page */
    left: 0;
    /* Align to the left of the page */
    height: 100%;
    /* Set height to 100% of the viewport height */
    width: 100%;
    /* Set width to 100% of the viewport width */
}

.lic__imgsize {
    height: 100vh;
    /* Set image height to 100% of the parent container */
    width: 100vw;
    /* Set image width to 100% of the parent container */
    object-fit: cover;
    /* Scale image to fill parent container */
}

.lic__container {
    height: 100vh;
    width: 100vw;

}

.lic__bg {
    position: absolute;
}

.lic__title {
    font-size: 7vw;
    z-index: 999;
    font-family: 'Lexend', sans-serif;
    color: white;
    letter-spacing: -0.2vw;
    margin-bottom: 20px;
}

.lic__content {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.lic__ontop {
    position: relative;
    display: flex;
    justify-content: center;
}

.lic__ontop * {
    margin: 0.5vw;
}