.products__itemcontainer {
    width: 100vw;
    margin-top: 3vw;
    height: 10vw;
    position: relative;
    z-index: 999;
    cursor: pointer;
    margin-bottom: 12vw;
}

.products__item {
    height: 10vw;
    width: 86vw;
    margin-left: 7vw;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 7vw;
    font-weight: 700;
    letter-spacing: -0.4vw;
    align-items: center;
}

.products__itemcontainer:hover {
    color: rgb(154, 154, 154);
}

.products__prodimg {
    /* height: 29.8vw;*/

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1vw;
    margin-bottom: 1vw;
    cursor: pointer;
    margin-top: 5vh;


}

.products__pluginimgsize {
    width: 40vw;

}

.products__chorusimgsize {
    width: 25vw;

}

.product__blur {
    background: rgba(0, 0, 0, 0.346);
    backdrop-filter: blur(5px);
    position: absolute;
    width: 20vw;
    height: 5vw;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product__title {
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
    color: white;
    letter-spacing: -0.06em;
}

.item__aurora{
    transform: translate(0px, 30px);
    margin-bottom: 70vh;
}



@media (max-width: 650px) {
    .products__pluginimgsize {
        width: 80vw;

    }

    .products__chorusimgsize {
        width: 50vw
    }

    .item__chorus {
        transform: translate(0px, 25vw);
    }

    .product__blur {
        width: 40vw;
        height: 10vw;
    }


    .product__title {
        font-size: 6vw;
    }
}
