@font-face {
    font-family: "Avenir";
    src: local("Avenir"),
        url('./assets/Avenir-Book-01.ttf') format("truetype");
    font-weight: lighter;
}

@font-face {
    font-family: "Avenir Medium";
    src: local("Avenir Medium"),
        url('./assets/Avenir Medium.ttf') format("truetype");
    font-weight: medium;
}

@font-face {
    font-family: "Avenir Black";
    src: local("Avenir Black"),
        url('./assets/Avenir Black.ttf') format("truetype");
    font-weight: bold;
}



@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700&display=swap');




.header__item {
    font-family: 'Avenir Medium';
    letter-spacing: -0.002vw;
}

div {
    /*font-family: 'Lexend', sans-serif;*/
    font-family: 'DM Sans', sans-serif;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

@media (max-width: 650px) {
    .header__mobilecontents {
        display: flex;
        flex: 1;
        padding-left: 2vw;
    }

    .header__items {
        display: none;
    }

    .header__logo {
        flex: 1;
        margin-left: -2vw;
        transform: translate(0px, -23px);
    }

    .header__cartlogo {

        margin-top: 20px;

    }




    .salepage__prodinfo {
        flex-direction: column;
        height: 70vh;
    }

    .header__container {
        margin-top: 5vw;
    }

    .salepage__prodimg {
        padding-top: 5vw;
    }

    .content-container {
        flex-direction: column;
    }

    .left-column {
        width: 2vw;

    }

    .salepage__leftside {
        height: 20vw;
        flex: 1;
        width: 100vw;

    }

    .salepage__rightside {
        height: 20vw;
        flex: 1;
        width: 100vw;
    }

    .salepage__sliders {
        /*width: 100vw;
        transform: rotate(90deg);*/
        display: none;

    }

    .salepage__title {
        color: white;
        font-size: 20.5vw;
        font-weight: 700;
        letter-spacing: -1.7vw;
    }

    .salepage__description {
        color: white;
        margin-right: 5vw;
        font-size: 5vw;
        letter-spacing: -0.1vw;
        width: 90vw;
    }

    .salepage__sliderssize {
        transform: translate(0px, 0vw) rotate(90deg);

    }

    .about__gradient {
        display: none;
    }

}

@media (min-width: 650px) {
    .left-column {
        width: calc(33.33% - 6.4vw);
    }

}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 50vw;
    width: 90vw;
    position: absolute;
    border-radius: 20px;

}

.noise::before{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: "";
    /* you can control opacity of noise  */
    opacity: .01;  
    z-index: 1000;
    pointer-events: none;
    background: url(noise.gif) ;
}