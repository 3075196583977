@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');




.salepage__prodimg {
    /* height: 29.8vw;*/
    height: 51vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1vw;
    margin-bottom: 1vw;
    min-height: 200px;
    max-height: 50vw;
}

.salepage__prodinfo {
    height: 30vw;
    display: flex;
    margin: 0px 7vw;
}

.salepage__leftside {
    height: 20vw;
    flex: 1;
}

.salepage__rightside {
    height: 20vw;
    flex: 1;
}

.salepage__sliders {
    width: 15vw;
}

.salepage__title {
    color: white;
    font-size: 6.5vw;
    font-weight: 700;
    letter-spacing: -0.4vw;

}

.salepage__container {
    position: relative;
    z-index: 1;
    overflow-x: hidden;
}

.salepage__img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
    background: -webkit-linear-gradient(to right, #152018, #2C3B31);
    background: linear-gradient(to right, #152018, #2C3B31);
    animation: gradient 15s ease infinite;
    background-size: cover;
}


.salepage__imgchorus {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
    background: -webkit-linear-gradient(to right, #3E5C6D, #9F576E);
    background: linear-gradient(to right, #1f2d35, #492832);
    animation: gradient 15s ease infinite;
    background-size: cover;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.salepage__ontop {
    position: relative;
}

.salepage__imgsize {
    height: 300vh;
    max-width: 100vw;
    overflow: hidden;
}

.salepage__pluginimgsize {
    width: 52vw;
    height: 100px;
    max-width: 90vh;
    border-radius: 10px;
    overflow: hidden;
}

.salepage__pluginimgsizechorus {
    width: 52vw;
    height: 100px;
    max-width: 90vh;
    border-radius: 10px;
    overflow: hidden;
}


.salepage__description {
    color: white;
    margin-right: 5vw;
    font-size: 1.7vw;
    letter-spacing: -0.07vw;
    width: 35vw;

}


.salepage__titleright {
    color: white;
    font-size: 6.5vw;
    font-weight: 700;
    letter-spacing: -0.4vw;
    text-align: right;
    white-space: nowrap;
    margin-right: 3vw;
}

.salepage__addtocartbutton {
    margin-top: 1vw;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(7.7vw);
}

.salepage__addtocartbuttonphone {
    display: none;
}

.addtocartsize {
    transform: translate(6.8vw);
}

.salepage__sliders {
    margin-top: 1vw;

}

.salepage__sliderssize {}

.salepage__buttontext {
    position: absolute;
    color: white;
    margin-top: -1vw;
    font-size: 1.4vw;
    transform: translate(7vw);

}

.salepage__price {
    width: 92%;
    text-align: right;
    font-size: 3.5vw;
    color: white;
    /*font-family: 'Inter', sans-serif;*/
    font-family: 'DM Sans', sans-serif;
    letter-spacing: -0.27vw;

}

.salepage__video {
    margin-left: 5vw;

}

.hiddenbutton {
    display: none;
}

@media (max-width: 650px) {
    .salepage__prodinfo {
        flex-direction: row;

    }

    .salepage__rightside {
        display: flex;
        margin-top: -120px;
        flex-direction: column;

    }

    .salepage__titleright {
        text-align: left;
        font-size: 15vw;
        letter-spacing: -1vw;
        display: none;
    }

    .salepage__addtocartbutton {
        display: none;
    }


    .salepage__addtocartbuttonphone {
        margin-top: 1vw;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(7.7vw);
        margin-right: 30vw;
    }

    .salepage__buttontext {
        font-size: 4vw;
    }

    .salepage__price {
        text-align: left;
        font-size: 15vw;
        letter-spacing: -0.9vw;
        margin-top: 10vw;
    }

    .salepage__video {
        margin-top: -20px;
        margin-bottom: 50px;
    }



}



.salepage__infobox {
    width: 37vw;
    height: 270px;
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.28);
    background: rgba(0, 0, 0, 0.07);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.18) inset;
    padding: 3vw;
    margin-top: -20px;
}

.salepage__moreinfo {
    display: flex;
    width: 90vw;
    justify-content: space-between;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 100px;
    color: white;
}

.salepage__infoboxtitle {
    font-weight: 600;
    font-size: 2vw;
}

.salepage__infoboxtext {
    font-size: 1.3vw;
}

.salepage__formatimgs {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    display: flex;

}

.salepage__formatimgs img {
    width: 8vw;

}

@media (max-width: 850px) {
    .salepage__video {}

    .salepage__infoboxtitle {
        font-size: 3.8vw;
    }

    .salepage__infoboxtext {
        font-size: 2.8vw;
    }

}

.error__message__signup {
    color: white;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 115px;
    font-weight: 900;
}


.error__message__signin {
    color: white;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 900;
}