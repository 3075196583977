.admin__container {
    background-color: #303236;
    width: 100vw;
    height: 100vh;
}

.admin__licmanager {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    flex-direction: column;
}

.admin__input {
    width: 90vw;
    height: 7vh;
    outline: none;
    border: none;
    border-radius: 50px;
    background-color: #242526;
    font-size: 20px;
    text-align: center;
    color: white;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 2vh;
}

.admin__manager {
    height: 68vh;
    background-color: #242526;
    width: 90vw;
    border-radius: 30px;
    padding-top: 4vh;
    overflow-y: auto;
}

.admin__product {
    color: white;
    margin-bottom: 1.5vh;
    margin-left: 3vw;
}

.admin__button {
    background-color: #242526;
    color: white;
    border: none;
    outline: solid;
    border-radius: 50px;
    height: 4vh;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-top: 0.5vh;
    margin-bottom: 0.3vh;
    font-family: 'DM Sans', sans-serif;
}

.admin__line {
    width: 90%;
    background-color: rgba(255, 255, 255, 0.114);
    height: 1.5px;
    border-radius: 10px;
    margin-top: 1.5vh;
}

.admin__logocontainer {
    color: white;
    width: 100vw;
    justify-content: center;
    display: flex;
    font-size: 2.3vw;
    align-items: center;
    padding-top: 2vh;
}

.admin__logo {}

/* Other styles in admin.css */

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    text-align: center;
    border-radius: 20px;
}

.modal-content button {
    margin: 0 10px;
    padding: 5px 20px;
    background-color: #fefefe;
    border: solid;
    border-radius: 20px;
    width: 8vw;
}


.modal-content input {
    padding: 5px;
    font-size: 20px;
    width: 100%;
}

.add-license-button {
    background-color: #6c64bd;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }