.home__container {
    font-family: 'DM Sans', sans-serif;
    color: #163470;
}

.home__infoandimgcontainer {
    height: 100vh;
    width: 100vw;
    margin-left: -1vw;
    margin-top: -10vw;
    z-index: 999;
}

.home__backgroundcolor {
    height: 100vh;
    width: 99vw;
    position: absolute;
    /*background-color: #EDF2E2;*/
}



.home__leftinfo {
    width: 30vw;
    padding-left: 15vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: red;


}

.home__left__title {
    font-size: 6vw;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    letter-spacing: -0.3vw;
}

.home__left__infotext {
    font-size: 1.7vw;
    font-family: 'DM Sans', sans-serif;
    letter-spacing: -0.04vw;
    font-weight: 100;
    margin-top: -5px;
}

.home__left__buttonscontainer {}

.home__left__button {
    width: 10vw;
    background-color: red;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
}

.home__rightpic {}