.signup__infocontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 65vh;
}

.signup__container {
  height: 100vh;

}

.signup__inputcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.signup__headertext {
  color: white;
  font-size: 3.5vw;
  letter-spacing: -0.11vw;
}

.signup__input {
  height: 0vw;
  outline: none;
  border: none;
  font-size: 3.3vw;
  border-radius: 100px;
  margin-top: 1.3vw;
  padding: 3vw;
  font-size: 1.7vw;
  width: 31vw;
  font-family: 'Lexend', sans-serif;
  font-weight: 300;
  letter-spacing: -0.07vw;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B5B5B5;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #B5B5B5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #B5B5B5;
}

.signup__button {
  position: absolute;
  width: 20vw;
  background-color: white;
  margin-top: 22.4vw;
  cursor: pointer;
  padding: 2vw 7.3vw;
  border-radius: 100px;
  display: flex;
  justify-content: center;
}

.signup__buttoncontainer {

  width: 5vw;
  background-color: 'white';
}

.signup__already {
  margin-top: 15px;
  color: rgb(34, 34, 162);
  font-size: 1.1vw;
  letter-spacing: -0.03vw;
  text-decoration: underline;
  cursor: pointer;
}

.signin__already {
  margin-top: 18px;
  color: rgb(136, 136, 233);
  font-size: 1.1vw;
  letter-spacing: -0.03vw;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

@media (max-width: 650px) {
  .signup__input {
    width: 80vw;
    height: 50px;
    font-size: 3.7vw;
    padding-left: 8vw;
    margin-bottom: 4px;
  }

  .signup__button {
    margin-top: 50vw;
    width: 41vw;
  }
  .signin__button {
    margin-top: 50vw;
    width: 41vw;
  }

  .signup__headertext {
    font-size: 7.5vw;
    letter-spacing: -0.3vw;
  }

  .signup__already {
    font-size: 3.1vw;
    letter-spacing: -0.09vw;
  }
  
  .signin__already {
    font-size: 3.1vw;
    letter-spacing: -0.09vw;
  }
}

.signin__plugingap{
  height: 50px;
}

.reset__gap{
  height: 100px;
}

.reset__error{
  margin-top: 20px;
}

.support__container .signin__already{
  font-size: 20px;
}

.signup__checkbox{

}

.signup__checkbox {
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: white;

  margin-top: 1.5vw;
}

.signup__checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.signup__checkbox label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
  color: #ffffff;
  
}

.signup__checkbox label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;

  border-radius: 200px;
  background-color: #fff;
  transition: 0.3s;
}

.signup__checkbox input[type="checkbox"]:checked + label::before {
  background-color: #9232cd;
  border-color: #00e408;
}

.signup__checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 2px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}


.signup__gap{
  height: 100px;
}