.home-screen {
    display: flex;
    background-color: black;
    min-height: 100vh;
    flex-direction: column;
    /*background: linear-gradient(#edf2e2, #DAF8FF);*/
    margin-bottom: 30px;

}

.home-margin {}

.left-column,
.middle-column,
.right-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.middle-column {
    align-items: center;
}

.left-column {

    margin-left: 6.4vw;
    /* color: #038961;*/
    color: white;
    z-index: 2;
}

.middle-column {
    width: 66.66%;
}

.right-column {
    width: 33.33%;
}

.home-title {
    font-size: 8vw;
    font-weight: bold;
    letter-spacing: -0.4vw;
    font-family: 'Avenir Black';

    align-items: top;

    margin-bottom: -1vw;


}

.shop-now-btn {
    background-color: #038961;
    /*background: linear-gradient(#EECE13, #B210FF);*/
    /* background-color: #5A78DF;*/
    background-color: rgba(12, 222, 159, 0.3);
    backdrop-filter: blur(20px);
    display: flex;
    color: white;
    border-radius: 15px;

    padding: 10px 20px;
    cursor: pointer;
    height: 85px;
    width: 25vw;
    margin-top: 25px;
    font-weight: bold;
    font-family: 'DM Sans', sans-serif;
    font-size: 1vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: white;
    letter-spacing: -0.05vw;
}

.menu-item {
    display: flex;
    cursor: pointer;
    padding: 20px;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    height: 95px;
    margin-right: 6.4vw;
    /*color: #038961;*/
    justify-content: center;
    margin-left: 2vw;
    color: white;
    align-items: center;
}

.menu-item.selected {
    /*background-color: #038961;*/
    background-color: rgba(12, 222, 159, 0.3);
    color: white;
    backdrop-filter: blur(20px);
}

.bottom-left,
.bottom-right {
    position: absolute;
    bottom: 30px;
    /*color: #038961;*/
    color: white;
}

.bottom-left {
    left: 6.4vw;
}

.bottom-right {
    right: 6.4vw;
    text-decoration: underline;
    cursor: pointer;
}

.content-container {
    display: flex;
    min-height: calc(100vh - 6.5vw);
    margin-top: -65px;
}

.home-info {
    font-family: 'Lexend';
    font-size: 2.1vw;
    letter-spacing: -0.055vw;
    font-weight: 200;
    width: 100%;
    margin-top: -1.1vw;
}

.menu-item-title {
    font-size: 1.5vw;
    font-weight: bold;
    letter-spacing: -0.05vw;
}

.home-img {
    width: 48vw;
    filter: drop-shadow(3px 3px 5px #838383);

}


.scrolled-content {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
}

.text-column {
    width: calc(33.33% - 8vw);
}

.text-column.left {
    margin-left: 10vw;
}

.text-column.right {
    margin-right: 10vw;
}

.middle-column {
    position: sticky;
    top: 0;
    padding-left: 5vw;
}

.home-line-container {
    justify-content: center;
    translate: transform(0px, 100px);
}

.home-line {
    background-color: #EDF2E2;

    width: 200px;
    height: 1px;
    margin-top: 1px;
}

body,
html {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}

.home-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /*filter: blur(3px);*/
    margin-bottom: 40px;
}

.home-blurry {
    width: 100%;
    height: 120px;
    background-color: rgba(68, 68, 68, 0.2);
    position: absolute;
    backdrop-filter: blur(20px);
}

@media (max-width: 650px) {
    .home-title {
        font-size: 20vw;
        font-weight: bold;
        letter-spacing: -0.8vw;
        font-family: 'Avenir Black';
        margin-top: 15vh;
        align-items: top;


    }

    .home-info {
        font-family: 'Lexend';
        font-size: 4.5vw;
        letter-spacing: -0.03vw;
        font-weight: 200;
        width: 80vw;
        margin-top: -1.1vw;
    }

    .shop-now-btn {
        width: 90vw;
        font-size: 5vw;
        letter-spacing: -0.3vw;
    }

    .home-img {
        width: 80vw;
        margin-top: 5vh;
        margin-left: 25vw;
    }
}

.home__bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
    background-color: black;
    background-size: cover;
}