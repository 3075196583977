.header__container {
    width: 98vw;
    height: 120px;
    display: flex;
    z-index: 999;
    position: relative;

}

.header__logo {
    display: flex;
    width: 60px;
    margin-left: calc(6.4vw - 10px);
    justify-self: center;
    align-items: center;

}

.header__items {
    flex: 1;
    padding-left: 7vw;
    padding-right: 7vw;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    ;



}

.header__cartlogo {
    display: flex;
    width: 60px;
    margin-right: calc(6.4vw - 12px) ;
    justify-self: center;
    justify-content: right;
    align-items: right;
    margin-top: 40px;
    cursor: pointer;
}

.header__itemcontainer {
    display: flex;
    padding: 0px 17vw;
    height: 100%;
    align-items: center;
}

.header__item {
    flex: 1;
    margin: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.header__item:hover {
    color: #37C69B;
}

.header__mobilecontents {
    display: none;
    cursor: pointer;
    z-index: 99999;
}

.header__menu path:hover {
    fill: blue;
}

.header__mobilecontents:hover {
    fill: blue;
}

.header__cartlogo:hover {
    color: rgb(154, 154, 154);
    fill: rgb(154, 154, 154);
}

.header__menuopen{
    height: calc(100vh - 120px) ;
    width: 50vw;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    position: absolute;
    margin-top: 120px;
    backdrop-filter: blur(25px);

}

.header__menu__item{
    color: white;
    font-size: 8vw;
    margin-top: 30px;
    margin-left: 12px;
    letter-spacing: -0.4vw;
    cursor: pointer;
}

.hamburger-menu {
    position: relative;
    transform: translate(3.6vw, 22px);
  }
  
  .hamburger {
    width: 30px;
    height: 23px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .hamburger .line {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease;
    border-radius: 3px;
  }
  
  .hamburger.open .line:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }
  
  .hamburger.open .line:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.open .line:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }
  
  .menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
  
  .menu.open {
    transform: translateX(0);
  }
  