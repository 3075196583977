.checkout__paymentcomponent {
    width: 50%;
    transform: translate(-38px, 45px)
}

.checkout__contentcontainer {
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.checkout__info {
    color: black;

}

.checkout__screen {

    background-color: white;
    width: 1000px;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    padding-top: 20px;
    filter: drop-shadow(3px 3px 3px #1a211b);
    min-height: 700px;
    padding-bottom: 40px;
}

.cartitem__container {
    width: 408px;
    height: 95px;

    border-radius: 11px;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: center;
    margin-top: 50px;
}

.cartitem__image {
    width: 67px;
    height: 31px;
    z-index: 999;
    margin-left: 18px;
    margin-right: 15px;
}

.cartitem__name {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -2.56px;
    flex: 1;
}

.cartitem__price {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.6px;
    right: 0;
    margin-right: 20px;
}


.checkouttotal__container {
    width: 385px;
    height: 67px;
}

.checkouttotal__line {
    height: 1px;
    width: 385px;
    flex-shrink: 0;
    background: rgba(217, 217, 217, 0.50);
}

.checkouttotal__flexcontainer {
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
}

.checkouttotal__title {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -2.56px;
    margin-left: 15px;
}


.checkouttotal__pricecontainer {
    display: flex;
}

.checkouttotal__price {
    color: #000;
    margin-right: 15px;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -2.56px;
}

.checkouttotal__currency {
    color: #989898;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.96px;
    margin-top: 19px;
    margin-right: 1px;
}

.checkout__whitespace {
    height: 430px;
}

.cartitem__imagesize {
    width: 67px;
    height: 31px;

}

.checkout__middleline {
    width: 1px;
    height: 630px;
    background: rgba(217, 217, 217, 0.50);
    position: absolute;
    margin-top: 20px;
    margin-left: 0px;
}

.checkout__countryselector {
    position: absolute;
    width: 300px;
    transform: translate(-230px, 10px);
    z-index: 999;
}

.billing__container {
    width: 90%;
    margin-left: 2.5%;
}

.billing__inputs {
    display: flex;
    flex-direction: column;
}

.billing__inputs * {}

.billing__input {
    height: 30px;
    margin-top: 10px;
    border-radius: 7px;
    padding: 5px;
    border: none;
    outline: none;
    background-color: #f0f0f0;
    padding-left: 10px;

}

.billing__terms {
    display: flex;
    flex-direction: row;
}

.billing__termstext {
    display: flex;
    flex-direction: row;
}

.billing__termsunderlined {
    color: #7C7FD2;
    text-decoration: underline;
    cursor: pointer;
}

.billing__checkbox {}

.billing__checkboxstyle {}

.checkout__nextpagebutton {
    background-color: #f0f0f0;
    width: 120px;
    height: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    border-radius: 7px;
    right: 0;
    margin-top: 100px;
    transform: translate(342px, 0px);
}

.country__size {
    margin-top: 10px;
}

.billing__title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.7px;
    margin-left: 5px;
}

.checkout__option {
    border-radius: 5px;
    background: #090909;
    height: 38px;
    flex-shrink: 0;
    color: white;
    color: #FFF;
    text-align: center;
    font-size: 16.8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.344px;
    cursor: pointer;
    width: 90%;
    margin-left: 2.5%;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    margin-bottom: 50px;
}

.checkout__optiontitle{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.checkout__paypal{
    width: 90%;
    margin-left: 2.5%;
}

@media (max-width: 950px) {
    .checkout__screen {
        flex-direction: column;
    }

    .checkout__whitespace {
        display: none;
    }

    .checkout__paymentcomponent {
        width: 100%;
        transform: translate(0px, 0px);
    }

    .cartitem__container{
        width: 94%;
        margin-left: 3%;
        margin-right: 3%;
    }

    .checkouttotal__flexcontainer{
        width: 98vw;
    }
}

.checkout__trypaypal{
    margin-bottom: 10px;
}